// LandingPage.js
import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from './AuthContext';

const BackgroundBox = styled(Box)(({ theme }) => ({
    backgroundImage: `
    linear-gradient(
      rgba(255, 255, 255, 0.5), 
      rgba(255, 255, 255, 0.5)
    ), 
    url(/background.webp)
  `,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    color: theme.palette.primary.contrastText,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
}));

function LandingPage() {
    const { login } = useAuth();

    const handleBegin = () => {
        login();
    };

    return (
        <BackgroundBox>
            <Box
                sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    p: 4,
                    borderRadius: 2,
                    textAlign: 'center',
                }}
            >
                <Typography variant="h2" sx={{ mb: 2, fontWeight: 600 }}>
                    Stratezy Labs
                </Typography>
                <Typography variant="h3" sx={{ mb: 2, fontWeight: 600 }}>
                    Welcome to Smart Trading Strategy Research Platform
                </Typography>
                <Typography variant="h5" sx={{ mb: 4 }}>
                    Empowering your trading decisions with advanced analytics and visualization.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleBegin}
                    sx={{ fontWeight: 600 }}
                >
                    Let's Begin
                </Button>
            </Box>
        </BackgroundBox>
    );
}

export default LandingPage;
