// KeycloakService.js
import Keycloak from 'keycloak-js';

let keycloak = null;

const initializeKeycloak = (onAuthenticatedCallback) => {
  if (!keycloak) {
    keycloak = new Keycloak({
        url: 'https://stratezylabs.ai/auth',
      realm: 'stockserver',
      clientId: 'stockserver-react',
    });

    keycloak
      .init({
        onLoad: 'check-sso',
        checkLoginIframe: false,
        pkceMethod: 'S256',
      })
      .then((authenticated) => {
        onAuthenticatedCallback(authenticated);
      })
      .catch((error) => {
        console.error('Keycloak initialization failed:', error);
        keycloak = null;
        onAuthenticatedCallback(false);
      });
  } else {
    onAuthenticatedCallback(keycloak.authenticated);
  }
};

const initKeycloak = (onAuthenticatedCallback) => {
  initializeKeycloak(onAuthenticatedCallback);
};

const getAccessToken = () => keycloak?.token || null;
const getRefreshToken = () => keycloak?.refreshToken || null;

const refreshToken = () => {
  return keycloak
    .updateToken(30)
    .then((refreshed) => {
      if (refreshed) {
        console.log('Token successfully refreshed');
      }
    })
    .catch((error) => {
      console.error('Failed to refresh token:', error);
    });
};

const logout = () => {
  keycloak.logout({
    redirectUri: window.location.origin + '/',
  });
};

const login = () => {
  keycloak.login({
    redirectUri: window.location.origin + '/app',
  });
};

const KeycloakService = {
  initKeycloak,
  getAccessToken,
  getRefreshToken,
  refreshToken,
  logout,
  login,
  getKeycloak: () => keycloak,
};

export default KeycloakService;
