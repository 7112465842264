// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import KeycloakService from '../services/KeycloakService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        let refreshInterval;

        KeycloakService.initKeycloak((authenticated) => {
            setIsAuthenticated(authenticated);

            if (authenticated) {
                setAuthToken(KeycloakService.getAccessToken());

                refreshInterval = setInterval(() => {
                    KeycloakService.refreshToken().then(() => {
                        setAuthToken(KeycloakService.getAccessToken());
                    });
                }, 60000);
            } else {
                setAuthToken(null);
            }
        });

        return () => {
            if (refreshInterval) {
                clearInterval(refreshInterval);
            }
        };
    }, []);

    const login = KeycloakService.login;

    const logout = () => {
        setIsAuthenticated(false);
        setAuthToken(null);
        KeycloakService.logout();
    };

    return (
        <AuthContext.Provider value={{ authToken, isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
