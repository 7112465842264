// InputForm.js
import React from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    TextField,
    Button,
    Grid,
    Typography,
    CircularProgress,
    Box,
    useTheme,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/theme-monokai';
import './customStyles.css';
import { paperStyle } from './styles';

function InputForm({
    internalSymbol,
    setInternalSymbol,
    internalVarList1,
    setInternalVarList1,
    internalVarList2,
    setInternalVarList2,
    internalStartDate,
    setInternalStartDate,
    internalEndDate,
    setInternalEndDate,
    internalCustomRule,
    setInternalCustomRule,
    internalError,
    internalLoading,
    handleSubmit,
}) {
    const theme = useTheme();

    return (
        <Card variant="outlined" sx={paperStyle}>
            {/* <CardHeader
        title="Custom Chart Visualization"
        sx={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
      /> */}
            <CardContent>
                {internalError && (
                    <Typography color="error" gutterBottom>
                        {internalError}
                    </Typography>
                )}
                <Grid container spacing={2}>
                    {/* Symbol Field */}
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Symbol"
                            value={internalSymbol}
                            onChange={(e) => setInternalSymbol(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    {/* Var List 1 Field */}
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Var List 1 (comma-separated)"
                            value={internalVarList1}
                            onChange={(e) => setInternalVarList1(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    {/* Var List 2 Field */}
                    <Grid item xs={12} sm={4}>
                        <TextField
                            label="Var List 2 (comma-separated)"
                            value={internalVarList2}
                            onChange={(e) => setInternalVarList2(e.target.value)}
                            fullWidth
                        />
                    </Grid>

                    {/* Start Date Picker */}
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Start Date"
                                value={internalStartDate}
                                onChange={(newValue) => setInternalStartDate(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    {/* End Date Picker */}
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="End Date"
                                value={internalEndDate}
                                onChange={(newValue) => setInternalEndDate(newValue)}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>

                    {/* Custom Rule Editor */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom>
                            Custom Rule
                        </Typography>
                        <Box sx={{ width: '100%', height: '400px', mt: 2 }}>
                            <AceEditor
                                mode="python"
                                theme="monokai"
                                value={internalCustomRule}
                                onChange={(value) => setInternalCustomRule(value)}
                                name="customRuleEditor"
                                editorProps={{ $blockScrolling: true }}
                                width="100%"
                                height="100%"
                                className="ace-editor"
                            />
                        </Box>
                    </Grid>

                    {/* Submit Button and Loading Indicator */}
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                            {internalLoading && (
                                <CircularProgress
                                    size={24}
                                    sx={{ ml: 2, verticalAlign: 'middle' }}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default InputForm;
