// App.js
import React, { useState, useEffect, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import AppContent from './components/AppContent';
import LandingPage from './components/LandingPage';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
    const [darkMode, setDarkMode] = useState(() => {
        const storedDarkMode = sessionStorage.getItem('darkMode');
        return storedDarkMode ? JSON.parse(storedDarkMode) : false;
    });

    useEffect(() => {
        sessionStorage.setItem('darkMode', JSON.stringify(darkMode));
    }, [darkMode]);

    const appTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: darkMode ? 'dark' : 'light',
                    primary: {
                        //main: '#e25f5b', // red - zerodha
                        //main: '#CBC3E3', //light purple
                        main: '#6A5ACD', //Slate Blue
                    },
                },
                typography: {
                    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
                    h1: { fontWeight: 600 },
                    h2: { fontWeight: 600 },
                    h3: { fontWeight: 600 },
                    h4: { fontWeight: 600 },
                    h5: { fontWeight: 600 },
                    h6: { fontWeight: 600 },
                    subtitle1: { fontWeight: 500 },
                    subtitle2: { fontWeight: 500 },
                    body1: { fontWeight: 400 },
                    body2: { fontWeight: 400 },
                    button: { fontWeight: 500 },
                    caption: { fontWeight: 400 },
                    overline: { fontWeight: 400 },
                },
            }),
        [darkMode]
    );


    return (
        <ThemeProvider theme={appTheme}>
            <AuthProvider>
                <Router>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route
                            path="/app/*"
                            element={
                                <ProtectedRoute>
                                    <AppContent />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </Router>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
