// ProtectedRoute.js
import React from 'react';
import { useAuth } from './AuthContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated === null) {
        // Authentication status is being determined
        return null; // Or a loading indicator
    }

    if (isAuthenticated === false) {
        // User is not authenticated, redirect to landing page
        return <Navigate to="/" replace />;
    }

    // User is authenticated
    return children;
};

export default ProtectedRoute;
