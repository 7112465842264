import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import {
    Box,
    TextField,
    List,
    ListItemText,
    Divider,
    Paper,
    Grid,
    Typography,
    Snackbar,
    Alert,
    useTheme,
    ListItemAvatar,
    IconButton,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Card,
    CardHeader,
    InputAdornment,
    Skeleton,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Tooltip,
    ListItemButton as MuiListItemButton,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import {
    CloudUpload as DeploymentIcon,
    Refresh as RefreshIcon,
    Search as SearchIcon,
} from '@mui/icons-material';
import { useAuth } from './AuthContext.js';
import TradeDataDialog from './TradeDataDialog';
import TradeDataDialogToDo from './TradeDataDialogToDo';
import { paperStyle } from './styles';

const StyledListItem = styled(MuiListItemButton)(({ theme, ...props }) => ({
    backgroundColor: props.selected ? theme.palette.action.selected : 'transparent',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

function Deployment() {
    const theme = useTheme();
    const { authToken } = useAuth();
    const [backtests, setBacktests] = useState([]);
    const [deployments, setDeployments] = useState([]);
    const [selectedBacktest, setSelectedBacktest] = useState(null);
    const [selectedDeployment, setSelectedDeployment] = useState(null);
    const [textareaValue, setTextareaValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchDeploymentText, setSearchDeploymentText] = useState('');
    const [exchange, setExchange] = useState('NSE');
    const [capital, setCapital] = useState('1000000');
    const [brokerage, setBrokerage] = useState('ZERODHA');
    const [ndays, setNdays] = useState('0');
    const [selectedDeploymentId, setSelectedDeploymentId] = useState(null);

    const [openTradeDataDialog, setOpenTradeDataDialog] = useState(false);
    const [openTradeDataDialogToDo, setOpenTradeDataDialogToDo] = useState(false);
    const [openTradeDataDialogN, setOpenTradeDataDialogN] = useState(false);
    const [tradeData, setTradeData] = useState([]);
    const [tradeDataToDo, setTradeDataToDo] = useState([]);
    const [tradeDataN, setTradeDataN] = useState([]);
    // Snackbar state
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error'); // 'success', 'error', 'warning', 'info'

    const [apiResponse, setApiResponse] = useState(""); // State to hold API response
    const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control popup visibility
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };
    // Fetch deployments on component mount
    useEffect(() => {
        fetchData();
        fetchDeploymentData();
    }, []);

    // Sample handler functions for REST calls:
    const handleActivate = async (strategy) => {
        try {
            setLoading(true);
            const response = await axios.get(
                `https://stratezylabs.ai/deploy/strategy/activate?name=${strategy}&exchange=NSE&brokerage=ZERODHA&activate=true`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            console.log(response.data);
            setSnackbarMessage(response.data);
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error activating strategy:', error);
            setSnackbarMessage(`Error activating strategy - ${error.message || error}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleDeactivate = async (strategy) => {
        try {
            if (!strategy) {
                alert('Please select a strategy to deactivate.');
                return;
            }
            const userConfirmed = window.confirm(`Are you sure you want to deactivate deployed strategy ${strategy}?`);
            if (!userConfirmed) {
                return; // Exit if user cancels
            }
            setLoading(true);
            const response = await axios.get(
                `https://stratezylabs.ai/deploy/strategy/activate?name=${strategy}&exchange=NSE&brokerage=ZERODHA&activate=false`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            console.log(response.data);
            setSnackbarMessage(response.data);
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error deactivating strategy:', error);
            setSnackbarMessage(`Error deactivating strategy - ${error.message || error}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (strategy) => {
        if (!strategy) {
            alert('Please select a strategy to delete.');
            return;
        }
        const userConfirmed = window.confirm(`Are you sure you want to delete deployed strategy ${strategy}?`);
        if (!userConfirmed) {
            return; // Exit if user cancels
        }
        setLoading(true);
        const requestBody = {
            exchangeId: 'nse',
            strategyName: strategy,
            brokerage: 'ZERODHA',
        };

        try {
            const response = await axios.post(
                'https://stratezylabs.ai/strategy/delete',
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            console.log("Delete response - ", response.data)
            setSnackbarMessage(`Strategy - ${strategy} deleted successfully`);
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error deleting strategy:', error);
            setSnackbarMessage(`Error deleting strategy - ${error.message || error}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleView = async (strategy) => {
        if (!strategy) return;
        try {
            setLoading(true);
            const response = await axios.get(
                `https://stratezylabs.ai/deploy/strategy/viewall?name=${strategy}&exchange=NSE&brokerage=ZERODHA`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            //console.log(response.data);
            setTradeData(response.data);
            setOpenTradeDataDialog(true);
        } catch (error) {
            console.error('Error viewing strategy trade table:', error);
            setSnackbarMessage(`Error viewing trades strategy - ${error.message || error}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleViewN = async (strategy) => {
        if (!strategy) return;
        try {
            setLoading(true);
            const response = await axios.get(
                `https://stratezylabs.ai/deploy/strategy/viewn?name=${strategy}&days=${ndays}&exchange=NSE&brokerage=ZERODHA`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            //console.log(response.data);
            setTradeDataN(response.data);
            setOpenTradeDataDialogN(true);
        } catch (error) {
            console.error('Error viewing strategy trade table:', error);
            setSnackbarMessage(`Error viewing trades strategy - ${error.message || error}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleShow = async (strategy) => {
        if (!strategy) return;
        try {
            setLoading(true);
            const response = await axios.get(
                `https://stratezylabs.ai/deploy/strategy/backtest?name=${strategy}&exchange=NSE&brokerage=ZERODHA`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            console.log(response.data);
            
            const prettyJson = JSON.stringify(response.data, null, 2); // Set the API response in state
            setApiResponse(prettyJson);
            setIsPopupOpen(true); // Open the popup
        } catch (error) {
            console.error('Error getting strategy backtest:', error);
            setSnackbarMessage(`Error viewing strategy backtest rule - ${error.message || error}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const handleViewToDo = async (strategy) => {
        if (!strategy) return;
        try {
            setLoading(true);
            const response = await axios.get(
                `https://stratezylabs.ai/deploy/strategy/view?name=${strategy}&exchange=NSE&brokerage=ZERODHA`,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            //console.log(response.data);
            setTradeDataToDo(response.data);
            setOpenTradeDataDialogToDo(true);
        } catch (error) {
            console.error('Error viewing strategy trade table:', error);
            setSnackbarMessage(`Error viewing strategy trade table- ${error.message || error}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };
    async function handleRebalance(id) {
        // Replace with your actual endpoint and logic
        const response = await fetch(`/api/deployments/${id}/rebalance`, { method: 'POST' });
        if (!response.ok) {
            console.error('Failed to rebalance');
        } else {
            console.log('Rebalanced successfully');
        }
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                'https://stratezylabs.ai/command/backtest/findall',
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            setBacktests(response.data);
            console.log("backtest response -", response.data);
            if (response.data.length > 0) {
                handleSelection(response.data[0]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch backtests. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    const fetchDeploymentData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                'https://stratezylabs.ai/deploy/strategy/findall',
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            setDeployments(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            //setError('Failed to fetch deployments. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    const handleDeploy = async () => {
        if (!selectedBacktest) {
            alert('Please select a backtest to deploy.');
            return;
        }

        setLoading(true);
        const requestBody = {
            requestId: selectedBacktest.requestId,
            exchangeId: exchange,
            initialCapital: capital,
            strategyName: selectedBacktest.name || selectedBacktest.requestId,
            //description: textareaValue,
            brokerage,
        };

        try {
            const response = await axios.post(
                'https://stratezylabs.ai/strategy/deploy',
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            setSnackbarMessage(`${response.data.message}`);
            setSnackbarOpen(true);
            setTextareaValue('');
        } catch (error) {
            console.error('Error during deploy:', error);
            setSnackbarMessage(`Failed to deploy, pls try again, error - ${error}`)
            setSnackbarSeverity('error');
            setSnackbarOpen(true)
        } finally {
            setLoading(false);
        }
    };

    const filteredBacktests = useMemo(
        () =>
            backtests.filter((deployment) =>
                (deployment.name || deployment.requestId)
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
            ),
        [backtests, searchText]
    );

    const filteredDeployments = useMemo(
        () =>
            deployments.filter((deployment) =>
                (deployment.name || deployment.requestId)
                    .toLowerCase()
                    .includes(searchDeploymentText.toLowerCase())
            ),
        [deployments, searchDeploymentText]
    );


    const handleSelection = (deployment) => {
        console.log("handleBacktestSelection - ", deployment);
        setSelectedBacktest(deployment);
        setTextareaValue(deployment.summary || ''); // Pre-fill text area with the `summary` field
    };

    const handleDeploymentSelection = (deployment) => {
        setSelectedDeployment(deployment);
    };

    const actionButtonStyles = {
        minWidth: 100,
        size: 'large'
    };

    return (
        <div>
            <Card
                variant="outlined"
                sx={paperStyle}
            >
                <CardHeader
                    title="Deployments"
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        py: 2,
                        px: 3,
                        '& .MuiCardHeader-title': {
                            fontSize: '1.25rem',
                            fontWeight: 'bold'
                        }
                    }}
                />
                <Box
                    display="flex"
                    bgcolor={theme.palette.background.default}
                    sx={{
                        pb: 4,
                        px: 2
                    }}
                >
                    <Grid container spacing={2}>
                        {/* List Section */}
                        <Grid item xs={12} md={4}>
                            <Paper
                                elevation={6}
                                sx={paperStyle}
                            >
                                {/* Backtest search section */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mb: 2,
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom fontWeight="bold">
                                        Search Backtests Profile
                                    </Typography>
                                    <IconButton onClick={fetchData} color="primary" size="small">
                                        <RefreshIcon />
                                    </IconButton>
                                </Box>

                                <TextField
                                    fullWidth
                                    label="Search Backtests"
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    variant="outlined"
                                    margin="normal"
                                    sx={{ mb: 2 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                {loading ? (
                                    <List>
                                        {[...Array(5)].map((_, index) => (
                                            <MuiListItemButton key={index}>
                                                <Skeleton variant="text" width="100%" />
                                            </MuiListItemButton>
                                        ))}
                                    </List>
                                ) : error ? (
                                    <Typography color="error">{error}</Typography>
                                ) : (
                                    <List sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
                                        {filteredBacktests.map((deployment) => (
                                            <React.Fragment key={deployment.requestId}>
                                                <StyledListItem
                                                    selected={selectedBacktest?.requestId === deployment.requestId}
                                                    onClick={() => handleSelection(deployment)}
                                                    sx={{
                                                        '&.Mui-selected': {
                                                            backgroundColor: theme.palette.action.hover,
                                                        }
                                                    }}
                                                >
                                                    <ListItemAvatar>
                                                        <DeploymentIcon color="action" />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={deployment.name || deployment.requestId}
                                                        primaryTypographyProps={{ fontWeight: 'medium' }}
                                                    />
                                                </StyledListItem>
                                                <Divider component="li" />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                )}

                                {/* Deployment search section */}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        mt: 4,
                                        mb: 2,
                                    }}
                                >
                                    <Typography variant="h6" gutterBottom fontWeight="bold">
                                        Search Deployment
                                    </Typography>
                                    <IconButton onClick={fetchDeploymentData} color="primary" size="small">
                                        <RefreshIcon />
                                    </IconButton>
                                </Box>
                                <TextField
                                    fullWidth
                                    label="Search Deployments"
                                    value={searchDeploymentText}
                                    onChange={(e) => setSearchDeploymentText(e.target.value)}
                                    variant="outlined"
                                    margin="normal"
                                    sx={{ mb: 2 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                {loading ? (
                                    <List>
                                        {[...Array(5)].map((_, index) => (
                                            <MuiListItemButton key={index}>
                                                <Skeleton variant="text" width="100%" />
                                            </MuiListItemButton>
                                        ))}
                                    </List>
                                ) : error ? (
                                    <Typography color="error">{error}</Typography>
                                ) : (
                                    <List sx={{ border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
                                        {filteredDeployments.map((deployment) => (
                                            <React.Fragment key={deployment.name}>
                                                <StyledListItem
                                                    selected={selectedDeployment?.name === deployment.name}
                                                    onClick={() => handleDeploymentSelection(deployment)}
                                                    sx={{
                                                        '&.Mui-selected': {
                                                            backgroundColor: theme.palette.action.hover,
                                                        }
                                                    }}
                                                >
                                                    <ListItemAvatar>
                                                        <DeploymentIcon color="action" />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={deployment.name}
                                                        primaryTypographyProps={{ fontWeight: 'medium' }}
                                                    />
                                                </StyledListItem>
                                                <Divider component="li" />
                                            </React.Fragment>
                                        ))}
                                    </List>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {/* Main Content Section */}
                            <Grid sx={{ mt: 2, mb: 2 }}>
                                {backtests.length > 0 ? (
                                    <Paper
                                        elevation={6}
                                        sx={paperStyle}
                                    >
                                        <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
                                            Backtest: {selectedBacktest?.name || selectedBacktest?.requestId || ""}
                                        </Typography>
                                        <TextField
                                            label="Backtest Details"
                                            multiline
                                            rows={10}
                                            fullWidth
                                            InputProps={{
                                                readOnly: false,
                                                sx: {
                                                    fontFamily: '"Fira Code", "JetBrains Mono", "Courier Prime", monospace',
                                                    whiteSpace: 'pre-wrap',
                                                    backgroundColor: theme.palette.background.paper,
                                                    p: 2,
                                                    //mt: 4,
                                                    mb: 4,
                                                    borderRadius: 1,
                                                },
                                                spellCheck: false,
                                            }}
                                            value={textareaValue}
                                            onChange={(e) => setTextareaValue(e.target.value)}
                                            variant="outlined"
                                        />
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            gap={2}
                                            flexWrap="wrap"
                                        >
                                            <FormControl sx={{ width: { xs: '100%', sm: '30%' } }}>
                                                <TextField
                                                    label="Initial Capital (Rs)"
                                                    type="number"
                                                    value={capital}
                                                    onChange={(e) => setCapital(parseFloat(e.target.value) || '')}
                                                />
                                            </FormControl>
                                            <FormControl sx={{ width: { xs: '100%', sm: '30%' } }}>
                                                <InputLabel>Exchange</InputLabel>
                                                <Select
                                                    value={exchange}
                                                    onChange={(e) => setExchange(e.target.value)}
                                                >
                                                    <MenuItem value="NSE">NSE</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl sx={{ width: { xs: '100%', sm: '30%' } }}>
                                                <InputLabel>Brokerage</InputLabel>
                                                <Select
                                                    value={brokerage}
                                                    onChange={(e) => setBrokerage(e.target.value)}
                                                >
                                                    <MenuItem value="ZERODHA">ZERODHA</MenuItem>
                                                </Select>
                                            </FormControl>

                                            <Tooltip title="Deploy Strategy" arrow>
                                                <IconButton
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={handleDeploy}
                                                    disabled={loading}
                                                    sx={{
                                                        backgroundColor: theme.palette.primary.main,
                                                        color: theme.palette.primary.contrastText,
                                                        '&:hover': {
                                                            backgroundColor: theme.palette.primary.dark,
                                                        }
                                                    }}
                                                >
                                                    <SendIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </Paper>
                                ) : (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        height="100%"
                                        flexDirection="column"
                                        sx={{ m: 2 }}
                                    >
                                        <DeploymentIcon sx={{ fontSize: 80, color: 'text.secondary' }} />
                                        <Typography
                                            variant="h6"
                                            color="text.secondary"
                                            sx={{ mt: 2 }}
                                        >
                                            Select a backtest to view details
                                        </Typography>
                                    </Box>
                                )}
                            </Grid>
                            <Grid sx={{ mt: 2, mb: 2 }}>
                                {deployments.length > 0 ?
                                    <Paper
                                        elevation={6}
                                        sx={paperStyle}
                                    >
                                        <Typography variant="h6" fontWeight="bold">
                                            Deployment Details: {selectedDeploymentId || ""}
                                        </Typography>

                                        <Box sx={{ width: '100%', overflowX: 'auto' }}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold' }}>Deployment Date</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold' }}>Brokerage</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold' }}>Active</TableCell>
                                                        <TableCell sx={{ fontWeight: 'bold' }}>Initial Capital</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {deployments.map((dep) => (
                                                        <TableRow
                                                            key={dep.name}
                                                            hover
                                                            selected={dep.name === selectedDeploymentId}
                                                            onClick={() => setSelectedDeploymentId(dep.name)}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            <TableCell>{dep.name}</TableCell>
                                                            <TableCell>{dep.deployedDate}</TableCell>
                                                            <TableCell>{dep.dataDate}</TableCell>
                                                            <TableCell>{dep.brokerage}</TableCell>
                                                            <TableCell>{dep.state}</TableCell>
                                                            <TableCell>{dep.active}</TableCell>
                                                            <TableCell>{dep.initialCapital}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Box>

                                        {/* Two Rows of Buttons, Evenly Distributed */}
                                        <Box display="flex" flexDirection="column" gap={2} mt={2} width="100%">
                                            {/* Top Row: ViewAll, View, Rebalance */}
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="stretch"
                                                gap={2}
                                                flexWrap="wrap"
                                                width="100%"
                                            >
                                                <Button
                                                    variant="contained"
                                                    onClick={() => handleView(selectedDeploymentId)}
                                                    disabled={!selectedDeploymentId}
                                                    color="info"
                                                    sx={{ ...actionButtonStyles, flex: '1 1 0' }}
                                                >
                                                    ViewOpen
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => handleViewToDo(selectedDeploymentId)}
                                                    disabled={!selectedDeploymentId}
                                                    color="info"
                                                    sx={{ ...actionButtonStyles, flex: '1 1 0' }}
                                                >
                                                    ViewLast
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    onClick={() => handleRebalance(selectedDeploymentId)}
                                                    disabled={!selectedDeploymentId}
                                                    color="info"
                                                    sx={{ ...actionButtonStyles, flex: '1 1 0' }}
                                                >
                                                    Rebalance
                                                </Button>
                                            </Box>

                                            {/* Bottom Row: Activate, Deactivate, Delete */}
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="stretch"
                                                gap={2}
                                                flexWrap="wrap"
                                                width="100%"
                                            >
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleActivate(selectedDeploymentId)}
                                                    disabled={!selectedDeploymentId}
                                                    sx={{ ...actionButtonStyles, flex: '1 1 0' }}
                                                >
                                                    Activate
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleDeactivate(selectedDeploymentId)}
                                                    disabled={!selectedDeploymentId}
                                                    sx={{ ...actionButtonStyles, flex: '1 1 0' }}
                                                >
                                                    Deactivate
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleDelete(selectedDeploymentId)}
                                                    disabled={!selectedDeploymentId}
                                                    sx={{ ...actionButtonStyles, flex: '1 1 0' }}
                                                >
                                                    Delete
                                                </Button>
                                            </Box>
                                            {/* Last Row: View for last N days */}
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="stretch"
                                                gap={2}
                                                flexWrap="wrap"
                                                width="100%"
                                                mt={2} // Add margin-top for spacing
                                            >
                                                <FormControl sx={{ flex: '1 1 0' }}>
                                                    <TextField
                                                        label="last N days"
                                                        type="number"
                                                        value={ndays}
                                                        onChange={(e) => setNdays(parseInt(e.target.value) || '')}
                                                    />
                                                </FormControl>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleViewN(selectedDeploymentId)}
                                                    disabled={!selectedDeploymentId}
                                                    sx={{
                                                        ...actionButtonStyles,
                                                        flex: '1 1 0', // Combined width of two buttons above
                                                    }}
                                                >
                                                    ViewTrades
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleShow(selectedDeploymentId)}
                                                    disabled={!selectedDeploymentId}
                                                    sx={{ ...actionButtonStyles, flex: '1 1 0' }}
                                                >
                                                    Show
                                                </Button>
                                            </Box>
                                            {isPopupOpen && (
                                                <div style={{
                                                    position: "fixed",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)",
                                                    backgroundColor: "white",
                                                    border: "1px solid #ccc",
                                                    borderRadius: "8px",
                                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                                    zIndex: 1000,
                                                    padding: "20px",
                                                    width: "500px", // Increased width for better visibility
                                                    textAlign: "center"
                                                }}>
                                                    <h3>API Response</h3>
                                                    <p style={{
                                                        wordWrap: "break-word",
                                                        whiteSpace: "pre-wrap",
                                                        fontFamily: "'Courier New', Courier, monospace", // Monospace font
                                                        fontSize: "14px",
                                                        textAlign: "left" // Align text to the left for better readability
                                                    }}>
                                                        {apiResponse}
                                                    </p>
                                                    <button onClick={handleClosePopup} style={{ marginTop: "20px" }}>
                                                        Close
                                                    </button>
                                                </div>
                                            )}

                                            {/* Backdrop for popup */}
                                            {isPopupOpen && (
                                                <div style={{
                                                    position: "fixed",
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                    zIndex: 999
                                                }} onClick={handleClosePopup}></div>
                                            )}

                                            {/* Backdrop for popup */}
                                            {isPopupOpen && (
                                                <div style={{
                                                    position: "fixed",
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                                    zIndex: 999
                                                }} onClick={handleClosePopup}></div>
                                            )}
                                        </Box>


                                    </Paper> :
                                    (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            height="100%"
                                            flexDirection="column"
                                            sx={{ m: 2 }}
                                        >
                                            <DeploymentIcon sx={{ fontSize: 80, color: 'text.secondary' }} />
                                            <Typography
                                                variant="h6"
                                                color="text.secondary"
                                                sx={{ mt: 2 }}
                                            >
                                                Select a deployed strategy to view details
                                            </Typography>
                                        </Box>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
            {/* Separate Component Dialog */}
            <TradeDataDialog
                open={openTradeDataDialog}
                onClose={() => setOpenTradeDataDialog(false)}
                tradeData={tradeData}
                name={selectedDeploymentId}
                message="Open Trades"
                showSell={false}
            />

            <TradeDataDialogToDo
                open={openTradeDataDialogToDo}
                onClose={() => setOpenTradeDataDialogToDo(false)}
                tradeData={tradeDataToDo}
                name={selectedDeploymentId}
            />

            <TradeDataDialog
                open={openTradeDataDialogN}
                onClose={() => setOpenTradeDataDialogN(false)}
                tradeData={tradeDataN}
                name={selectedDeploymentId}
                message={`Trades for ${ndays} days`}
                showSell={true}
            />
            {/* Error Snackbar */}
            {(
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',
                            whiteSpace: 'pre-wrap',
                            fontFamily: 'monospace',
                            fontSize: '14px',
                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );



}

export default React.memo(Deployment);
