import React, { useState, useMemo } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    TextField,
    TableSortLabel,
    Box,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

// Helper function to sort the table rows
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Styled component for the header cells (shaded background)
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.grey[200],
    fontWeight: 'bold'
}));

function TradeDataDialog({ open, onClose, tradeData, name, message, showSell }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('symbol');
    const [filterSymbol, setFilterSymbol] = useState('');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    // Filtered data based on filterSymbol input
    const filteredData = useMemo(() => {
        return tradeData.filter(row =>
            row.symbol.toLowerCase().includes(filterSymbol.toLowerCase())
        );
    }, [tradeData, filterSymbol]);

    // Sorted data based on current order and orderBy
    const sortedData = useMemo(() => {
        const comparator = getComparator(order, orderBy);
        return [...filteredData].sort(comparator);
    }, [filteredData, order, orderBy]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>{name} - {message}</DialogTitle>
            <DialogContent dividers>
                <Box mb={2}>
                    <Typography variant="subtitle1" gutterBottom>
                        Filters
                    </Typography>
                    <TextField
                        label="Filter by Symbol"
                        variant="outlined"
                        size="small"
                        value={filterSymbol}
                        onChange={(e) => setFilterSymbol(e.target.value)}
                    />
                </Box>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            {[
                                { id: 'symbol', label: 'Symbol' },
                                { id: 'number', label: 'Number' },
                                { id: 'buyPrice', label: 'Buy Price' },
                                { id: 'sellPrice', label: 'Price' },
                                { id: 'principal', label: 'Principal' },
                                { id: 'investment', label: 'Investment' },
                                { id: 'netProfit', label: 'Net Profit' },
                                { id: 'profit', label: 'Profit (%)' },
                                { id: 'anPrf', label: 'Annual Prf(%)' },
                                { id: 'buyTime', label: 'Buy Time' },
                                ...(showSell ? [
                                    { id: 'sellTime', label: 'Sell Time' },
                                ] : []),
                                { id: 'duration', label: 'Duration Days' },
                                { id: 'risk1R', label: 'Risk1R' },
                                ...(showSell ? [
                                    { id: 'closeReason', label: 'Close Reason' },
                                    { id: 'isClosed', label: 'Is Closed' }
                                ] : [])
                            ].map((headCell) => (
                                <StyledTableCell key={headCell.id}>
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={orderBy === headCell.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(headCell.id)}
                                    >
                                        {headCell.label}
                                    </TableSortLabel>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{row.symbol}</TableCell>
                                <TableCell>{row.number}</TableCell>
                                <TableCell>{row.buyPrice}</TableCell>
                                <TableCell>{row.sellPrice}</TableCell>
                                <TableCell>{row.principal ? parseFloat(row.principal).toFixed(2) : " "}</TableCell>
                                <TableCell>{row.investment ? parseFloat(row.investment).toFixed(2) : " "}</TableCell>
                                <TableCell>{row.netProfit ? parseFloat(row.netProfit).toFixed(2) : " "}</TableCell>
                                <TableCell>{row.profit ? parseFloat(row.profit).toFixed(2) : " "}</TableCell>
                                <TableCell>{row.anPrf ? parseFloat(row.anPrf).toFixed(2) : " "}</TableCell>
                                <TableCell>{row.buyTime}</TableCell>
                                {showSell && <TableCell>{row.sellTime}</TableCell>}
                                <TableCell>{row.duration}</TableCell>
                                <TableCell>{row.risk1R ? parseFloat(row.risk1R).toFixed(2) : " "}</TableCell>
                                {showSell && <TableCell>{row.closeReason}</TableCell>}
                                {showSell && <TableCell>{row.closed ? 'Yes' : 'No'}</TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default TradeDataDialog;
