// AppContent.js
import React, { useState } from 'react';
import {
    Container,
    Typography,
    Tabs,
    Tab,
    AppBar,
    Toolbar,
    Button,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useAuth } from './AuthContext';
import { Navigate } from 'react-router-dom';
import BacktestForm from './BacktestForm';
import CustomChartVisualization from './CustomChartVisualization';
import Library from './Library';
import Deployment from './Deployment';
import BacktestIcon from '@mui/icons-material/Assessment';
import ChartIcon from '@mui/icons-material/ShowChart';
import LibraryIcon from '@mui/icons-material/LibraryBooks';
import BuildIcon from '@mui/icons-material/Build';


function AppContent() {
    const theme = useTheme();
    const { isAuthenticated, logout } = useAuth();
    const [tabIndex, setTabIndex] = useState(0);

    // Lifted state to keep data persistent across tabs
    const [chartData, setChartData] = useState({
        endDate: new Date(),
        startDate: new Date(),
        symbol: '',
        varList1: '',
        varList2: '',
        customRule: '',
        data: null,
        dataFetched: false,
        loading: false,
        error: null,
    });

    const [darkMode, setDarkMode] = useState(() => {
        const storedDarkMode = sessionStorage.getItem('darkMode');
        return storedDarkMode ? JSON.parse(storedDarkMode) : false;
    });

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    if (isAuthenticated === false) {
        // User is no longer authenticated, redirect to landing page
        return <Navigate to="/" replace />;
    }

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    return (
        <>
            {/* AppBar with Logout Button */}
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Trading Strategy Backtest Platform
                    </Typography>
                    <Button color="inherit" onClick={logout}>
                        Logout
                    </Button>
                </Toolbar>
            </AppBar>

            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant={isSmallScreen ? 'scrollable' : 'standard'}
                    scrollButtons={isSmallScreen ? 'auto' : 'off'}
                    centered={!isSmallScreen}
                    aria-label="navigation tabs"
                >
                    <Tab
                        icon={<BacktestIcon />}
                        label="Strategy Backtest"
                        sx={{
                            typography: isSmallScreen ? 'body2' : 'body1',
                        }}
                    />
                    <Tab
                        icon={<ChartIcon />}
                        label="Rule development"
                        sx={{
                            typography: isSmallScreen ? 'body2' : 'body1',
                        }}
                    />
                    <Tab
                        icon={<LibraryIcon />}
                        label="Rule Library"
                        sx={{
                            typography: isSmallScreen ? 'body2' : 'body1',
                        }}
                    />
                    <Tab
                        icon={<BuildIcon />}
                        label="Deploy Strategy"
                        sx={{
                            typography: isSmallScreen ? 'body2' : 'body1',
                        }}
                    />
                </Tabs>

                {tabIndex === 0 && <BacktestForm />}
                {tabIndex === 1 && (
                    <CustomChartVisualization
                        endDate={chartData.endDate}
                        startDate={chartData.startDate}
                        symbol={chartData.symbol}
                        varList1={chartData.varList1}
                        varList2={chartData.varList2}
                        customRule={chartData.customRule}
                        data={chartData.data}
                        dataFetched={chartData.dataFetched}
                        loading={chartData.loading}
                        error={chartData.error}
                        setChartData={setChartData}
                    />
                )}
                {tabIndex === 2 && (
                    <Library darkMode={darkMode} setDarkMode={setDarkMode} />
                )}
                {tabIndex === 3 && <Deployment />}
            </Container>
        </>
    );
}

export default AppContent;
